import React from 'react';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import {
  Button,
  Col,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from 'antd';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import {
  AppStore,
  UserStore,
  CurrentUserStore,
  CarStore,
  ShopStore,
  WorkOrderStore,
} from 'stores';
import { CarObject } from 'stores/Classes';
import { Logger } from 'stores/Classes';
import styled from 'styled-components';
import {
  WorkOrderFilterOptions,
  // WorkOrderRepairPriorityEnum,
  WorkOrderStatusEnum,
} from 'stores/Classes/WorkOrderObject';
import CurrentFilterBadges from 'components/WorkOrder/CurrentFilterBadges';
import { renderStatusFilterDropdown } from 'components/WorkOrder/WorkOrderRenderUtils';
import { Link } from 'react-router-dom';
import { currencyOrNumber } from 'shared/utils';

const TableContainer = styled(Table)`
  .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
  }
`;

const Wrapper = styled.div``;

const DropdownButtonContainer = styled.div`
  .more-button-dropdown {
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;

    i {
      font-size: 1.3rem;
      font-weight: bold;
      color: #000;
    }
  }
`;

const FlexColJustifyEnd = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const { Search } = Input;

class AssetWorkOrders extends React.Component {
  static propTypes = {
    car: PropTypes.instanceOf(CarObject),
    shopId: PropTypes.number,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      workOrders: [],
      currentSearch: '',
      statusFiltersApplied: [],
      filteredInfo: {},
      filterByStatusDropdownVisible: false,
      shopUsers: [],
      sorterData: {
        columnKey: 'started_at',
        order: 'descend',
      },
      shopWorkOrderStatuses: {},
    };
  }

  get currentFiltersApplied() {
    return [...this.state.statusFiltersApplied];
  }

  componentDidMount() {
    this.init();
  }

  async componentDidUpdate(prevProps, prevState) {
    let { currentSearch } = this.state;
    if (currentSearch !== prevState.currentSearch) {
      this.handleSearchChange();
    }
  }

  searchDebouncer = null;

  handleSearchChange = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.searchDebouncer);
    this.searchDebouncer = setTimeout(() => {
      this.reloadWorkOrders();
    }, 500);
  };

  init = async () => {
    await this.loadShopUsers();
    await this.loadShopWorkOrderStatuses();
    this.reloadWorkOrders();
  };

  loadShopUsers = async () => {
    try {
      this.setState({ loading: true });
      const users = await UserStore.fetchShopUsers(this.props.shopId);
      await this.setState({ shopUsers: users || [] });
    } catch (error) {
      AppStore.addError('Failed to load shop users. Please try again later.');
    } finally {
      this.setState({ loading: false });
    }
  };

  loadShopWorkOrderStatuses = async () => {
    const shopId = ShopStore.currentShop.id;
    try {
      this.setState({ isLoadingStatuses: true });
      const shopWorkOrderStatuses = await WorkOrderStore.getShopWorkOrderStatuses(
        shopId
      );

      this.setState({
        shopWorkOrderStatuses: _.chain(shopWorkOrderStatuses)
          .get('data', [])
          .reduce((acc, item) => {
            acc[item.key] = item.description;
            return acc;
          }, {})
          .value(),
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingStatuses: false });
    }
  };

  reloadWorkOrders = async () => {
    try {
      this.setState({ loading: true });
      const filters = {
        id_shop: this.props.shopId,
        carIds: this.props.car.id,
        limit: 999,
      };
      if (this.state.statusFiltersApplied.length > 0) {
        filters.status = this.state.statusFiltersApplied
          .map((filter) => filter.key)
          .join(',');
      }
      if (this.state.currentSearch !== '') {
        filters.search = this.state.currentSearch;
      }
      const sortColumn = get(this.state.sorterData, 'columnKey');
      const sortOrder = get(this.state.sorterData, 'order');
      if (sortColumn) {
        filters.sort = `${sortOrder === 'descend' ? '-' : ''}${sortColumn}`;
      }
      const { data: workOrders } = await WorkOrderStore.fetchWorkOrders(
        filters
      );
      this.setState({ workOrders });
    } catch (error) {
      AppStore.addError(error.message || 'Failed to load work orders');
    } finally {
      this.setState({ loading: false });
    }
  };

  handleMenuClick = async ({ key, domEvent }, row) => {
    domEvent.stopPropagation();
    domEvent.preventDefault();

    if (key === '1') {
      try {
        AppStore.addLoading('Getting Work Order Report');
        await WorkOrderStore.printWorkOrder(row.id);
      } catch (error) {
        AppStore.addError(
          'Unknown error to generate the Work Order. Please try again later'
        );
      } finally {
        AppStore.removeLoading('Getting Work Order Report');
      }
    } else if (key === '2') {
      try {
        AppStore.addLoading('Getting Work Order Report');
        await WorkOrderStore.downloadWorkOrder(row.id);
      } catch (error) {
        AppStore.addError(
          'Unknown error to generate the Work Order. Please try again later'
        );
      } finally {
        AppStore.removeLoading('Getting Work Order Report');
      }
    }
  };

  handleStatusChange = async (newStatus, row) => {
    try {
      const response = await WorkOrderStore.updateWorkOrderOnCustomEndpoint({
        id: row.id,
        status: newStatus,
      });

      // Check if we need to Resolve a DVIR
      if (
        newStatus === 'resolved' &&
        row.type === 'driver_identified' &&
        row.work_order_dvirs.length > 0
      ) {
        if (!CurrentUserStore.user.email) {
          throw new Error('An user is required to resolve the DVIR');
        }

        const samsaraUsers = await CarStore.fetchSamsaraUsers(
          ShopStore.currentShop.id
        );
        // Select the current user email if it's in the list
        const currentUserEmail = CurrentUserStore.user.email;
        const samsaraUserResolving = samsaraUsers.find(
          (user) =>
            String(user.email).toLowerCase() ===
            String(currentUserEmail).toLowerCase()
        );

        let defects = [];
        let dvirId = row.work_order_dvirs[0].dvir_id;
        row.work_order_dvirs.forEach((dvirDefects) => {
          defects.push({ id: dvirDefects.defect_id.toString() });
        });

        if (dvirId && defects.length > 0) {
          const defectsResolved = await CarStore.resolveDVIRDefects({
            shopId: ShopStore.currentShop.id,
            dvirId: dvirId,
            defects: defects,
            mechanicNotes: 'Resolved by Pitstop',
            resolvedBySamsaraUserId:
              samsaraUserResolving?.id || currentUserEmail,
          });
          AppStore.addNotification(
            `${defectsResolved.length} defects resolved successfully`
          );
        }
      }

      notification.success({
        message: 'Status updated',
        key: `statusUpdated${moment().format('x')}`,
        description: <>The work order status was updated.</>,
      });
      return response;
    } catch (error) {
      let friendlyMsg =
        "We couldn't update the Work Order status at this moment, please try again later";
      return notification.error({
        message: 'Oops!',
        key: `statusUpdateError${moment().format('x')}`,
        description: <>{friendlyMsg}</>,
      });
    }
  };

  onChangeCurrentFilters = () => {
    // update table filter based on the filters selected
    const filters = {
      status: [],
    };
    this.state.statusFiltersApplied.forEach((filter) => {
      filters['status'].push(filter.key);
    });
    this.setState({ filteredInfo: filters });
    // update the columns
    this.columns = this.columns.map((column) => {
      if (column.key === 'status') {
        return {
          ...column,
          filters: WorkOrderFilterOptions,
          filteredValue: filters['status'],
        };
      }
      return column;
    });
    this.reloadWorkOrders();
  };

  columns = [
    {
      dataIndex: 'invoice_number',
      editable: false,
      title: 'Order #',
      required: true,
      render: (wo, row) => {
        return <Link to={`/work-order/${row.id}/edit`}>{`#${wo}`}</Link>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      filters: WorkOrderFilterOptions,
      filterDropdown: (props) =>
        renderStatusFilterDropdown({
          ...props,
          onOk: (selectedKeys, confirm) => {
            this.setState(
              {
                statusFiltersApplied: selectedKeys.map((key) => {
                  const status = WorkOrderFilterOptions.find(
                    (s) => s.value === key
                  );
                  return {
                    key: key,
                    label: status?.text || key,
                  };
                }),
                filterByStatusDropdownVisible: false,
              },
              () => {
                confirm();
                this.onChangeCurrentFilters();
              }
            );
          },
          onReset: (clearFilters) => {
            clearFilters();
            this.setState({ statusFiltersApplied: [] }, () => {
              this.onChangeCurrentFilters();
            });
          },
        }),
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByStatusDropdownVisible: true });
          }}
        />
      ),
      // filteredValue: this.filteredInfo.status || '',
      render: (status, row) => {
        const source = Object.keys(this.state.shopWorkOrderStatuses).length
          ? this.state.shopWorkOrderStatuses
          : WorkOrderStatusEnum;

        const options = Object.keys(source)
          .map((workOrderStatusKey) => ({
            key: workOrderStatusKey,
            value: source[workOrderStatusKey],
          }))
          .map((workOrderStatus) => {
            return (
              <Select.Option
                key={workOrderStatus.key}
                value={workOrderStatus.key}
              >
                {workOrderStatus.value}
              </Select.Option>
            );
          });
        return (
          <Select
            defaultValue={status}
            style={{ width: 180 }}
            onChange={(evt) => this.handleStatusChange(evt, row)}
            onClick={(evt) => evt.stopPropagation()}
          >
            {options}
          </Select>
        );
      },
    },
    // {
    //   title: 'Repair Priority',
    //   dataIndex: 'priority',
    //   key: 'priority',
    //   sorter: true,
    //   render: (text) => {
    //     const formatted = WorkOrderRepairPriorityEnum[text] || text;
    //     return formatted;
    //   },
    // },
    {
      title: 'Created At',
      dataIndex: 'started_at',
      key: 'started_at',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (text) => {
        return text ? moment(text).format('MM/DD/YYYY') : 'Missing Date';
      },
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      sorter: true,
      render: (userId) => {
        const user = this.state.shopUsers.find((u) => u.userId === +userId);
        return user
          ? `${user.firstName} ${user.lastName}`.trim() || user.email
          : 'Unassigned';
      },
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (text, workOrder) => {
        return currencyOrNumber(_.get(workOrder, 'costsTotal', 0));
        // const subtotal = sumBy(workOrder.work_order_lines, (line) => {
        //   return parseFloat(line.labour_cost) + parseFloat(line.parts_cost);
        // });
        // const tax = workOrder.tax || 0;
        // const taxType = workOrder.tax_type || 'percentage';
        // const total =
        //   taxType === 'percentage'
        //     ? subtotal * (1 + tax / 100)
        //     : subtotal + tax;
        // return `$${total.toFixed(2)}`;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, row) => {
        const menu = (
          <Menu onClick={(evt) => this.handleMenuClick(evt, row)}>
            <Menu.Item key="1">
              <Icon type="printer" />
              Print work order
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="download" />
              Download work order
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <DropdownButtonContainer>
              <Button
                shape="circle"
                size="small"
                className="more-button-dropdown"
              >
                <Icon type="more" />
              </Button>
            </DropdownButtonContainer>
          </Dropdown>
        );
      },
    },
  ];

  render() {
    return (
      <Wrapper>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Search
              placeholder="Search by Order #"
              onChange={(event) => {
                this.setState({
                  currentSearch: event.target.value,
                });
              }}
            />
          </Col>
          <FlexColJustifyEnd span={18}>
            <Button
              onClick={() => {
                this.props.history.push(
                  `/work-order/add?assetId=${this.props.car.id}`
                );
              }}
              type="primary"
            >
              <Icon type="plus" />
              Add New
            </Button>
          </FlexColJustifyEnd>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <CurrentFilterBadges
              currentFiltersApplied={this.currentFiltersApplied}
              onClearAllFilters={() => {
                this.setState(
                  {
                    statusFiltersApplied: [],
                  },
                  () => {
                    this.onChangeCurrentFilters();
                  }
                );
              }}
              onCloseTag={(filter) => {
                this.setState(
                  {
                    statusFiltersApplied: this.state.statusFiltersApplied.filter(
                      (f) => f.key !== filter.key
                    ),
                  },
                  () => {
                    this.onChangeCurrentFilters();
                  }
                );
              }}
            />
          </Col>
        </Row>
        <TableContainer
          columns={this.columns}
          dataSource={this.state.workOrders}
          loading={this.state.loading}
          rowKey="id"
          onChange={(pagination, filters, sorter) => {
            this.setState({ sorterData: sorter }, () => {
              this.reloadWorkOrders();
            });
          }}
        />
      </Wrapper>
    );
  }
}

export default withRouter(AssetWorkOrders);
